export default [
  {
    title: 'Inicio',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Pedido',
    route: { name: 'order', params: { new: 1 } },
    icon: 'FilePlusIcon',
  },
  {
    title: 'Mis Pedidos',
    route: 'orders',
    icon: 'ListIcon',
  },
  // {
  //   title: 'Colaboradores',
  //   route: 'partners',
  //   icon: 'GlobeIcon',
  // },
  {
    title: 'Promociones',
    route: 'promotions',
    icon: 'GiftIcon',
    tag: 'Premium',
    tagVariant: 'light-success',
  },
  {
    title: 'Contactar',
    route: 'contact',
    icon: 'MailIcon',
  },
  {
    header: ' ',
  },
  {
    header: ' ',
  },
  {
    header: ' ',
  },
  {
    header: 'Info & Privacidad',
  },
  {
    title: 'Política Cookies',
    route:  'politicaCookies',
    icon: 'BellIcon',
  },
  {
    title: 'Política Privacidad',
    route: 'politicaPrivacidad',
    icon: 'BellIcon',
  },
  {
    title: 'Derecho Desestimiento',
    route: 'politicaDesistimiento',
    icon: 'BellIcon',
  },
  {
    title: 'Términos y Condiciones',
    route: 'terminosCondiciones',
    icon: 'BellIcon',
  },
]
